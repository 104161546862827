<template>
  <div class="m-2">
    <arq-previews-page />
    <h1>Consulta y paga tu última factura</h1>
    <form
      v-show="!verTabla"
      class="w-50 lg:w-25 mx-auto my-4"
      @submit.prevent="confirm()"
    >
      <div class="card p-2">
        <div
          v-for="(f, index) in form"
          :key="index"
        >
          <label :for="f.key">
            {{ f.label }}
          </label>
          <div v-if="types.includes(f.type)">
            <div v-if="f.key.includes('identification')">
              <b-form-input
                :id="f.key"
                v-model="data[f.key]"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
                @input="getContract(data[f.key])"
              />
            </div>
            <b-form-input
              v-else
              :id="f.key"
              v-model="data[f.key]"
              :name="f.key"
              :type="f.type"
              :required="f.required"
              :disabled="f.disabled"
            />
          </div>
          <div v-if="f.type === 'select-contract'">
            <b-form-select
              :id="f.key"
              v-model.number="data[f.key]"
              value-field="idProducto"
              text-field="descservicio"
              :options="contratos"
            />
          </div>
          <b-form-file
            v-if="f.type === 'file'"
            :id="f.key"
            v-model="data[f.key]"
            :state="Boolean(data[f.key])"
            placeholder="Adjuntar documento..."
            drop-placeholder="Suelta el documento aqui..."
          />
          <div v-if="f.type === 'textarea'">
            <b-form-textarea
              :id="f.key"
              v-model="data[f.key]"
              rows="8"
            />
          </div>
        </div>
        <b-button
          class="col-4"
          variant="primary mt-3"
          @click="confirm()"
        >
          Confirmar
        </b-button>
      </div>
    </form>
    <b-card
      v-if="verTabla"
      no-body
      class="card-statistics w-75 lg:w-25 mx-auto my-4"
    >
      <b-button
        variant="primary my-2"
        class="w-25 ml-lg-5"
        @click="verTabla = !verTabla"
      >
        Consultar otro contrato
      </b-button>
      <b-table
        class="mt-2 mx-auto w-75"
        responsive="sm"
        :items="facturas"
      >
        <template #cell(Saldo)="data">
          <span class="text-nowrap">
            {{ data.value | toCurrency }}
          </span>
        </template>
        <template #cell(Acciones)="data">
          <div v-if="data">
            <b-icon
              v-b-tooltip.hover.top="'Ver Factura'"
              class="mr-1"
              icon="eye"
              @click="openModal(data.item.NumeroDeCuenta)"
            />
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal
      ref="VerFactura"
      centered
      hide-footer
      no-close-on-backdrop
      title="Factura"
    >
      <ArqPdf
        :no-print="true"
        class="col col-lg-12"
        :src="pdfBase64"
      />
    </b-modal>
    <!-- <div
      v-if="facturas.length > 0"
      class="d-md-flex md:mx-4 mx-2"
    >
      <div class="col col-lg-3">
        <b-list-group flush>
          <div
            v-for="(factura, index) in facturas"
            :key="index"
            class=" cursor-pointer col"
            @click="descargarFactura(factura.account, index)"
          >
            <b-list-group-item :variant="factura.variant">
              <div class=" d-flex ">
                <feather-icon
                  icon="FileTextIcon"
                  class=" mx-2"
                />
                <p>
                  Año : <span class="mx-1">{{ factura.year }}</span>
                </p>
                <p>
                  Mes : <span class="mx-1">{{ factura.month }}</span>
                </p>
              </div></b-list-group-item>
          </div>
        </b-list-group>
      </div>
      <ArqPdf
        :no-print="true"
        class="col col-lg-9"
        :src="pdfBase64"
      />
    </div> -->
  </div>
</template>

<script>
import axios from 'axios'
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      currentPage: 0,
      pageCount: 0,
      data: {},
      pdfBase64: '',
      process: 'descargar-duplicados',
      title: 'Descarga de Duplicados',
      procedure: '/consultaFacturas',
      method: 'get',
      facturas: [],
      contratos: [],
      form: [
        // {
        //   label: 'Identificación',
        //   key: 'identification',
        //   type: 'number',
        //   required: true,
        // },
        {
          label: 'Contrato',
          key: 'contrato',
          type: 'number',
          required: true,
        },
      ],
      types: [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color',
      ],
      objeto: {},
      verTabla: false,
    }
  },
  mounted() {},
  methods: {
    descargarFactura(idCuenta) {
      this.pdfBase64 = ''
      // this.facturas = this.facturas.map(el => {
      //   const newEl = el
      //   newEl.variant = false
      //   return newEl
      // })
      // this.facturas[i].variant = 'primary'
      const fd = { idCuenta }
      this.sendGetProcedure('/descargaDuplicadoColilla', fd).then(res => {
        this.pdfBase64 = `data:application/pdf;base64,${res.data.object}`
        // this.facturas = res.data.list
      })
    },
    confirm() {
      this.facturas = []
      this.sendGetProcedure(this.procedure, this.data).then(res => {
        this.verTabla = true
        //  console.log('res', res)
        // this.facturas = res.data.list.map(el => ({
        //   Mes: el.month,
        //   Año: el.year,
        //   NumeroDeCuenta: el.account,
        //   Saldo: el.invoicedValue,
        //   Acciones: '',
        // }))
        res.data.list.forEach((el, index) => {
          if (index <= 0) {
            const data = {
              Mes: el.month,
              Año: el.year,
              NumeroDeCuenta: el.account,
              Saldo: el.invoicedValue,
              Acciones: '',
            }
            this.facturas.push(data)
          }
        })
      })
    },
    async sendGetProcedure(procedure, formData) {
      // const data = JSON.stringify(formData)
      return new Promise((resolve, reject) => {
        axios
          .get(`/app/procedure${procedure}`, { params: formData })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getContract(id) {
      this.contratos = []
      axios.get(`/client/contracts/${1}/${id}`).then(res => {
        this.contratos = res.data.list
      })
    },
    openModal(idCuenta) {
      this.$refs.VerFactura.show()
      this.descargarFactura(idCuenta)
    },
  },
}
</script>

<style></style>
